// extracted by mini-css-extract-plugin
export var clickArea = "index-module--clickArea--Iillq";
export var content = "index-module--content--c+ZHN";
export var contentHover = "index-module--contentHover--eBXdU";
export var descBg = "index-module--descBg--nz0ET";
export var descContent = "index-module--descContent--Pm8Xs";
export var experienceDemo = "index-module--experienceDemo--4SvyC";
export var experienceKPI = "index-module--experienceKPI--yc01Z";
export var guidance = "index-module--guidance--g71ty";
export var guidanceLogo = "index-module--guidanceLogo--m1CqZ";
export var hoverShow = "index-module--hoverShow--kMc+Q";
export var hoverUp = "index-module--hover-up--BPmgP";
export var position_left = "index-module--position_left--pZfYP";
export var securities = "index-module--securities--w+TPK";
export var viewData = "index-module--viewData--LdP1D";
export var watchVideo = "index-module--watchVideo--51gop";